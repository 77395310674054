import React, { useState, useEffect } from 'react';
import {
  faCloudArrowUp,
  faChartPie,
  faCreditCard,
  faSquarePollHorizontal
} from '@fortawesome/free-solid-svg-icons';
import {
  faChrome,
  faFirefoxBrowser,
  // faProductHunt, // Optional: Uncomment if needed
} from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MenuItem from './MenuItem';

const SideMenu = () => {
  const { t } = useTranslation();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 640);
  const [showAddToChrome, setShowAddToChrome] = useState(true);
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 640);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAddToChromeClick = () => {
    setShowAddToChrome(false);
  };

  return (
    <>
      {/* Desktop Sidebar */}
      {isDesktop && (
        <div className="flex flex-col min-h-screen">
          <nav className="bg-gradient-indigo-purple text-white flex flex-col flex-grow p-4 shadow-effect h-full w-16 sm:w-48 md:w-56 lg:w-64">
            <ul className="space-y-4 flex-grow">
              <MenuItem to="/" icon={faCloudArrowUp} label={t('upload')} />
              {userInfo && (
                <MenuItem to="/charts" icon={faChartPie} label={t('backlog')} />
              )}
              {userInfo && (
                <MenuItem
                  to="/dashboard"
                  icon={faSquarePollHorizontal}
                  label={t('charts')}
                />
              )}
              {!userInfo && (
                <MenuItem
                  to="/pricing"
                  icon={faCreditCard}
                  label={t('payments')}
                />
              )}

              {/* Chrome / Firefox - Desktop only */}
              {showAddToChrome && (
                <>
                  <li onClick={handleAddToChromeClick}>
                    <MenuItem
                      to="https://chromewebstore.google.com/detail/djgfcnkophbooaifbbfpbdnhhbbjknia?utm_source=item-share-cp"
                      icon={faChrome}
                      label="Add to Chrome"
                      external
                    />
                  </li>

                  <li onClick={handleAddToChromeClick}>
                    <MenuItem
                      to="https://addons.mozilla.org/en-US/firefox/addon/monchart-table-parser/"
                      icon={faFirefoxBrowser}
                      label="Add to Firefox"
                      external
                    />
                  </li>
                </>
              )}

              {/* 
              🔻 Product Hunt badge - optional future use
              <li className="hidden sm:block">
                <a
                  href="https://www.producthunt.com/posts/convert-html-tables-into-charts?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-convert&#0045;html&#0045;tables&#0045;into&#0045;charts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=908425&theme=light&t=1740641142871"
                    alt="Convert HTML Tables into Charts - Turn web data into charts instantly with our free extension | Product Hunt"
                    style={{ width: '250px', height: '54px' }}
                    width="250"
                    height="54"
                  />
                </a>
              </li>
              */}
            </ul>
          </nav>
        </div>
      )}

      {/* Mobile Bottom Navigation */}
      {!isDesktop && (
        <div className="fixed bottom-0 left-0 right-0 z-50 bg-gradient-indigo-purple shadow-md h-[64px]">
          <nav className="flex justify-around items-center text-white h-full">
            <MenuItem to="/" icon={faCloudArrowUp} label="" />
            {userInfo && <MenuItem to="/charts" icon={faChartPie} label="" />}
            {userInfo && (
              <MenuItem to="/dashboard" icon={faSquarePollHorizontal} label="" />
            )}
            {!userInfo && (
              <MenuItem to="/pricing" icon={faCreditCard} label="" />
            )}
          </nav>
        </div>
      )}
    </>
  );
};

export default SideMenu;
