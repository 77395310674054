import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MenuItem = ({ to, icon, label, external = false }) => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Default to true if screen is small

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update when screen resizes
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isActive = location.pathname === to;
  const baseClass = `text-base flex items-center justify-center sm:justify-start p-4 rounded-lg transition-all`;
  const activeClass = isActive ? 'text-yellow-400' : 'text-white hover:text-yellow-400';
  const iconClass = `w-6 h-6 ${isActive ? 'text-yellow-400' : 'text-white hover:text-yellow-400'}`;

  if (external) {
    return (
      <li className={isMobile ? 'block' : 'hidden sm:block'}>
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={label}
          className={`${baseClass} ${activeClass} border border-white rounded-lg p-3`}
        >
          <FontAwesomeIcon icon={icon} className={iconClass} />
          <span className="ml-3 hidden sm:inline text-left">{label}</span>
        </a>
      </li>
    );
  }

  return (
    <li className={isMobile ? 'block' : 'hidden sm:block'}>
      <Link to={to} aria-label={label} className={`${baseClass} ${activeClass}`}>
        <FontAwesomeIcon icon={icon} className={iconClass} />
        <span className="ml-3 hidden sm:inline text-left">{label}</span>
      </Link>
    </li>
  );
};

export default MenuItem;
