import React, { useEffect, lazy, Suspense, useState } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoadingSpinner from './components/shared/LoadingSpinner';
import SideMenu from './components/shared/SideMenu';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './config/i18n';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VerifyPage from './pages/VerifyPage';
import ResendVerificationPage from './pages/ResendVerificationPage';
import ResetPasswordPage from './pages/ResetPasswordPage';

// Initialize Stripe with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// Lazy load components for better performance
const Header = lazy(() => import('./components/shared/Header'));
const Homepage = lazy(() => import('./pages/Homepage'));
const ReportPage = lazy(() => import('./pages/ReportPage'));
const ChartPage = lazy(() => import('./pages/ChartPage')); 
const LookupPage = lazy(() => import('./pages/LookupPage'));
const LookupChromePage = lazy(() => import('./pages/LookupChromePage'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const DashboardApi = lazy(() => import('./pages/DashboardApi'));
const DashboardChrome = lazy(() => import('./pages/DashboardChrome'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const TrialPage = lazy(() => import('./pages/TrialPage'));
const SupportPage = lazy(() => import('./pages/SupportPage'));
const Footer = lazy(() => import('./components/shared/Footer'));
const CookieConsent = lazy(() => import('./components/shared/CookieConsent'));
const NotFound = lazy(() => import('./components/shared/NotFound'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const UploadPage = lazy(() => import('./pages/UploadPage'));
const CleanDataPage = lazy(() => import('./pages/CleanDataPage'));
const LoginSignup = lazy(() => import('./pages/LoginSignup'));
const CancelPage = lazy(() => import('./pages/CancelPage'));
const SuccessPage = lazy(() => import('./pages/SuccessPage'));

// Wrapper to parse query strings for /success route
const SuccessPageWrapper = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');

  if (!sessionId) {
    return <Navigate to="/not-found" replace />;
  }

  return <SuccessPage reportsuccessid={sessionId} />;
};

function App() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);
  const preferredTheme = useSelector((state) => state.theme.preferredTheme);
  const preferredLanguage = useSelector((state) => state.language.preferredLanguage);
  const { i18n } = useTranslation();

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-7CXQZ7N9PE');
    ReactGA.send('pageview');

    // Apply the preferred theme when the app loads
    document.documentElement.setAttribute('data-theme', preferredTheme);

    // Apply the preferred language when the app loads
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage);
    }
  }, [preferredTheme, preferredLanguage, i18n]);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  return (
    <Router>
      <Suspense fallback={<LoadingSpinner />}>
        <div className="flex flex-col min-h-screen">
          <Helmet>
            <title>MonChart | Convert data files to charts and analytical reports</title>
          </Helmet>

          <Header toggleSideMenu={toggleSideMenu} isSideMenuOpen={isSideMenuOpen} />
          <div className="flex flex-row flex-1">
            {isSideMenuOpen && <SideMenu />}
            <main className="flex-1 p-4">
              <Routes>
                {/* Define all the main routes */}
                <Route path="/" element={<Homepage />} />
                <Route path="/login" element={<LoginSignup />} />
                <Route path="/signup" element={<LoginSignup />} />
                <Route path="/verify/:token" element={<VerifyPage />} />
                <Route path="/resend-verification" element={<ResendVerificationPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                <Route path="/pricing" element={<TrialPage />} />
                <Route path="/support" element={<SupportPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route
                  path="/upload"
                  element={
                    <Elements stripe={stripePromise}>
                      <UploadPage />
                    </Elements>
                  }
                />
                <Route
                  path="/clean"
                  element={
                    <Elements stripe={stripePromise}>
                      <CleanDataPage />
                    </Elements>
                  }
                />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/cancel" element={<CancelPage />} />
                <Route path="/success" element={<SuccessPageWrapper />} />

                {/* Route for listing user reports */}
                <Route path="/dashboard" element={<ReportPage />} />
                <Route path="/dashboard/api/:reportId" element={<DashboardApi />} />
                <Route path="/dashboard/chrome/:reportId" element={<DashboardChrome />} />

                {/* Route for saved charts */}
                <Route path="/charts" element={<ChartPage />} />

                {/* Route for viewing specific chart */}
                <Route path="/charts/:chartId" element={<LookupPage />} />

                {/* Route for viewing a specific Chrome chart */}
                <Route path="/charts/chrome/:chartId" element={<LookupChromePage />} />

                {/* Route for detailed report view */}
                <Route path="/dashboard/:reportId" element={<Dashboard />} />

                {/* Catch-all for 404 */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
          </div>
          <Footer />
          <CookieConsent />
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
